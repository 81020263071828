import { React } from "react";
import { DateRangePicker } from "rsuite";
// import { startOfMonth, endOfMonth } from "date-fns";
import "../Styles/slide.css";

const Ranges = [
  {
    label: "Last 7 Days",
    value: [new Date(new Date().setDate(new Date().getDate() - 6)), new Date()],
    placement: "left",
  },
  {
    label: "Last 15 Days",
    value: [
      new Date(new Date().setDate(new Date().getDate() - 14)),
      new Date(),
    ],
    placement: "left",
  },
  {
    label: "Last 30 Days",
    value: [
      new Date(new Date().setDate(new Date().getDate() - 29)),
      new Date(),
    ],
    placement: "left",
  },
  {
    label: "Last 90 Days",
    value: [
      new Date(new Date().setDate(new Date().getDate() - 89)),
      new Date(),
    ],
    placement: "left",
  },
];
const Demo = ({ dateRangeValue, onChange, selectedSection }) => {
  const { beforeToday } = DateRangePicker;
  return (
    <>
        <DateRangePicker
          placeholder="Select Date Range"
          ranges={Ranges}
          format="dd-MMM-yy"
          character=" to "
          placement="autoVerticalEnd"
          size="lg"
          showOneCalendar={true}
          onChange={onChange}
          value={dateRangeValue}
          renderExtraFooter={() => null}
          cleanable={false}
          className="custom-date-range-picker"
        />
    </>
  );
};

export default Demo;
