// apiErrorHandling.js

export const handleApiError = (error) => {
  if (error.response) {
    const statusCode = error.response.status;
    const responseData = error.response.data;

    switch (statusCode) {
      case 400:
        console.error("Bad Request:", responseData);
        return "Bad Request";
      case 401:
        console.error("Unauthorized:", responseData);
        return "Unauthorized";
      case 403:
        console.error("Forbidden:", responseData);
        return "Forbidden";
      case 404:
        console.error("Not Found:", responseData);
        return "Not Found";
      case 422:
        console.error("Unprocessable Entity:", responseData);
        return "Unprocessable Entity";
      case 429:
        console.error("Too Many Requests:", responseData);
        return "Too Many Requests";
      case 500:
      case 502:
      case 503:
      case 504:
        console.error("Server Error:", responseData);
        return "Server Error";
      default:
        console.error("An unexpected error occurred:", responseData);
        return "Unexpected Error";
    }
  }

  return "Network Error";
};
