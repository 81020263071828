import React, { useState } from "react";
import TableRow from "./TableRow";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import SlideOver from "../Components/SlideOver";
import { Alert, Space } from 'antd';

const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

const BookingTable = ({
  bookings,
  currentPage,
  setCurrentPage,
  headers,
  setOpen,
  open,
  fetchBookings,
  fetchBookingsCount,
}) => {
  const bookingsPerPage = 10;
  const [selectedBooking, setSelectedBooking] = useState(null);
  const totalPages = Math.ceil(bookings.length / bookingsPerPage);

  const indexOfLastBooking = currentPage * bookingsPerPage;
  const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;
  const currentBookings = bookings.slice(
    indexOfFirstBooking,
    indexOfLastBooking
  );

  const [selectedSPOC, setSelectedSPOC] = useState({})
  const fetchBookingDetails = async (reservationCode) => {
    try {
      const url = getApiUrl(`/api/v1/merchant/bookings/viewBookings/${reservationCode}`);
    const response = await axios.get(url, {
      withCredentials: true,
    });
      setSelectedBooking(response.data.data);
      console.log("invite",response)
      setSelectedSPOC(response.data.data[0].spocName)
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  return (
    <div className="overflow-hidden border border-gray-300  rounded-tr-xl rounded-tl-xl h-fit rounded-br-xl rounded-bl-xl ">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                scope="col"
                className="py-2.5 pl-4  text-left text-sm font-normal text-gray-500 sm:pl-6"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white w-full">
          {currentBookings.map((person, index) => (
            <TableRow
              key={index}
              person={person}
              onRowClick={fetchBookingDetails}

            />
          ))}
        </tbody>
      </table>

      {/* {/ Pagination /} */}

      <div className="flex items-center  justify-between px-4 py-2 sm:px-6  border-t bg-white border-b rounded-br-xl rounded-bl-xl">
        <div className="flex justify-between  sm:hidden">
          <a
            href="/"
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Previous
          </a>
          <a
            href="/"
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          >
            Next
          </a>
        </div>

        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div className="mr-4">
            <p className="text-sm text-gray-700">
              <span>
                Showing{" "}
                {Math.min(
                  (currentPage - 1) * bookingsPerPage + 1,
                  bookings.length
                )}{" "}
                to {Math.min(currentPage * bookingsPerPage, bookings.length)} of{" "}
                <span>{bookings.length}</span> bookings
              </span>
            </p>
          </div>
          {bookings.length > 10 && (
            <div>
              <nav
                className="isolate inline-flex gap-2 -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                <button
                  onClick={() =>
                    setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)
                  }
                  className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-900  focus:z-20 focus:outline-offset-0 ${
                    currentPage === 1
                      ? "bg-white"
                      : "hover:bg-gray-50 border-none"
                  }`}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>

                {currentPage > 2 && (
                  <button
                    onClick={() => setCurrentPage(1)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-normal rounded-md text-gray-900 focus:outline-offset-0 ${
                      currentPage === 1
                        ? "bg-groupleBlue text-white"
                        : "border-none hover:bg-gray-50"
                    }`}
                  >
                    1
                  </button>
                )}

                {currentPage > 4 && (
                  <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900  focus:outline-offset-0">
                    ...
                  </span>
                )}

                {currentPage > 1 && (
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-normal rounded-md text-gray-900 focus:outline-offset-0 ${
                      currentPage === currentPage - 1
                        ? "bg-groupleBlue text-white"
                        : "border-none hover:bg-gray-50"
                    }`}
                  >
                    {currentPage - 1}
                  </button>
                )}

                <button
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-normal rounded-md text-white focus:outline-offset-0 bg-groupleBlue`}
                >
                  {currentPage}
                </button>

                {currentPage < Math.ceil(bookings.length / bookingsPerPage) && (
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-normal rounded-md text-gray-900 focus:outline-offset-0 ${
                      currentPage === currentPage + 1
                        ? "bg-groupleBlue text-white"
                        : "border-none hover:bg-gray-50"
                    }`}
                  >
                    {currentPage + 1}
                  </button>
                )}

                {currentPage <
                  Math.ceil(bookings.length / bookingsPerPage) - 2 && (
                  <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900  focus:outline-offset-0">
                    ...
                  </span>
                )}

                {currentPage <
                  Math.ceil(bookings.length / bookingsPerPage) - 1 && (
                  <button
                    onClick={() =>
                      setCurrentPage(
                        Math.ceil(bookings.length / bookingsPerPage)
                      )
                    }
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-normal rounded-md text-gray-900 focus:outline-offset-0 ${
                      currentPage ===
                      Math.ceil(bookings.length / bookingsPerPage)
                        ? "bg-groupleBlue text-white"
                        : "border-none hover:bg-gray-50"
                    }`}
                  >
                    {Math.ceil(bookings.length / bookingsPerPage)}
                  </button>
                )}

                <button
                  onClick={() =>
                    setCurrentPage(
                      currentPage ===
                        Math.ceil(bookings.length / bookingsPerPage)
                        ? Math.ceil(bookings.length / bookingsPerPage)
                        : currentPage + 1
                    )
                  }
                  className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-900 focus:z-20 focus:outline-offset-0 ${
                    currentPage === Math.ceil(bookings.length / bookingsPerPage)
                      ? "bg-white"
                      : "hover:bg-gray-50 border-none"
                  }`}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          )}
        </div>
      </div>

      {/* {/ SlideOver /} */}
      {selectedBooking && (
        <SlideOver
          booking={selectedBooking}
          onClose={() => setSelectedBooking(null)}
          setOpen={setOpen}
          open={open}
          currentSection={"bookings"}
          fetchBookings={fetchBookings} 
          fetchBookingsCount={fetchBookingsCount}
          selectedSPOCFromBooking={selectedSPOC}
        />
      )}
    </div>
  );
};

export default BookingTable;
