import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import LoginError from "../Pages/LoginError";

const AuthContext = createContext();

const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true"
  );
  const [error, setError] = useState(null);

  const login = async (formData) => {
    try {
      const url = getApiUrl("/api/usr/login/accepted/development");
      const response = await axios.post(url, formData, {
        withCredentials: true,
      });

      switch (response.status) {
        case 202:
          setAuthenticated(true);
          localStorage.setItem("authenticated", "true");
          break;
        default:
          setError("Your session expired, please login again.");
          break;
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Error logging in. Please try again later.");
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const logout = async () => {
    try {
      const url = getApiUrl("/api/usr/logout");
      await axios.get(url, { withCredentials: true });

      setAuthenticated(false);
      localStorage.clear();
      localStorage.removeItem("authenticated");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <AuthContext.Provider value={{ authenticated, login, logout }}>
      {error && <LoginError message={error} onClose={handleCloseError} />}
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
