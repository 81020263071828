import React, { useState, useEffect } from 'react';
import layer1 from "../Images/Layer_1.png";
import "../Styles/Landing.css"
import layer2 from "../Images/Layer_2 copy.png"

const Landing = () => {
  const [progress, setProgress] = useState(0);
  const [imageSize, setImageSize] = useState(35);
  const [animationFinished, setAnimationFinished] = useState(false);
  const progressBarWidth = '30%';

  useEffect(() => {
    const contentAnimationTimeout = setTimeout(() => {
      setAnimationFinished(true);
    },700); 

    return () => clearTimeout(contentAnimationTimeout);
  }, []);

  useEffect(() => {
    if (animationFinished) {
      const progressInterval = setInterval(() => {
        if (progress < 100) {
          setProgress(progress + 1);

          if (imageSize > 20) {
            setImageSize(imageSize - 1);
          }
        }
      }, 20);

      return () => clearInterval(progressInterval);
    }
  }, [progress, imageSize, animationFinished]);

  return (
    <div className='landing-wrapper'>
      <div className='landing-content'>
        <img src={layer1} alt="landing" />
      </div>

<div className="second">
  <img src={layer2} alt='landing2' style={{ width: `${imageSize}%`, maxWidth: '100%' }} />

  {imageSize <= 20 && (
    <div className="progress-bar-container">
      <div
        className="progress-bar"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  )}
</div>

    </div>
  );
};

export default Landing;