import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import "../Styles/slide.css";
import "react-phone-input-2/lib/style.css";
import { DatePicker } from "rsuite";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CountryFlag from "react-country-flag";

const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

export default function SlideOverThree({
  onClose,
  fetchBookingsCount,
  fetchBookings,
}) {
  const [open3, setOpen3] = useState(true);

  useEffect(() => {
    setOpen3(true);
  }, [onClose, setOpen3]);

  const handleClose = () => {
    setOpen3(false);
    onClose();
  };

  const [isIndividualTimeSlotOpenTwo, setIsIndividualTimeSlotOpenTwo] =
    useState(false);
  const [selectedIndividualTimeSlotTwo, setSelectedIndividualTimeSlotTwo] =
    useState(null);
  const individualTimeSlotOptionsTwo = [
    { value: "19:00", label: "7:00 PM" },
    { value: "19:30", label: "7:30 PM" },
    { value: "20:00", label: "8:00 PM" },
    { value: "20:30", label: "8:30 PM" },
    { value: "21:00", label: "9:00 PM" },
    { value: "21:30", label: "9:30 PM" },
    { value: "22:00", label: "10:00 PM" },
    { value: "22:30", label: "10:30 PM" },
    { value: "23:00", label: "11:00 PM" },
    { value: "23:30", label: "11:30 PM" },
    { value: "00:00", label: "12:00 AM" },
    { value: "00:30", label: "12:30 AM" },
    { value: "01:00", label: "1:00 AM" },
    { value: "01:30", label: "1:30 AM" },
    { value: "02:00", label: "2:00 AM" },
    { value: "02:30", label: "2:30 AM" },
    { value: "03:00", label: "3:00 AM" },
  ];

  const handleSelectedIndividualTimeSlot = (timeSlotIndividualValue) => {
    setSelectedIndividualTimeSlotTwo(timeSlotIndividualValue);
    setFormData((prevData) => ({
      ...prevData,
      bookingSlotStartTime: timeSlotIndividualValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      bookingSlotStartTime: "",
    }));
    setIsIndividualTimeSlotOpenTwo(false);
  };

  const toggleIndividualTimeSlotOpen = () => {
    setIsIndividualTimeSlotOpenTwo((prevState) => !prevState);
  };

  const OutletCode = localStorage.getItem("estateCode");
  const [formData, setFormData] = useState({
    bookerFirstName: "",
    bookerLastName: "",
    bookerEmail: "",
    bookerPhone: "",
    bookingDate: "",
    bookingSlotStartTime: "",
    bookingSlotEndTime: "",
    bookingTotalGuest: "",
    bookingNotes: "",
    bookerInternationalCode: "+971",
    reservationDate: new Date().toISOString().split("T")[0],
    outletCode: OutletCode,
    bookingGrouplePackageCategory: "A la carte",
    bookingPackageCategory: "À la Carte",
    bookingPackage: "General Reservations",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    setFormData((prevState) => ({
      ...prevState,
      bookingDate: formattedDate,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      bookingDate: "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.bookingDate) newErrors.bookingDate = "Date is required";
    if (!formData.bookingSlotStartTime)
      newErrors.bookingSlotStartTime = "Time slot is required";
    if (!formData.bookingTotalGuest) {
      newErrors.bookingTotalGuest = "Group size is required";
    } else if (
      formData.bookingTotalGuest < 1 ||
      formData.bookingTotalGuest > 20
    ) {
      newErrors.bookingTotalGuest =
        "Please select a valid number of people (1 to 20)";
    }
    if (!formData.bookerFirstName)
      newErrors.bookerFirstName = "First name is required";
    if (!formData.bookerLastName)
      newErrors.bookerLastName = "Last name is required";
    if (!formData.bookerEmail) newErrors.bookerEmail = "Email is required";
    if (!formData.bookerPhone)
      newErrors.bookerPhone = "Phone number is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddReservation = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setErrors({});
    let countryCode2 = formData.bookerInternationalCode || "";
    if (!countryCode2.startsWith("+")) {
      countryCode2 = "+" + countryCode2;
    }

    const phoneNumber = formData.bookerPhone || "";
    const countryCodePlain = countryCode2.replace("+", "");

    let phoneNumberWithoutCountryCode2;
    if (phoneNumber.startsWith(countryCodePlain)) {
      phoneNumberWithoutCountryCode2 = phoneNumber.slice(
        countryCodePlain.length
      );
    } else {
      phoneNumberWithoutCountryCode2 = phoneNumber;
    }

    try {
      const url = getApiUrl("/api/v1/merchant/bookings/addReservation");
      const response = await axios.post(
        url,
        {
          bookerFirstName: formData.bookerFirstName,
          bookerLastName: formData.bookerLastName,
          bookerEmail: formData.bookerEmail,
          bookerInternationalCode: countryCode2,
          bookerPhone: phoneNumberWithoutCountryCode2,
          bookingPackageCategory: "À la Carte",
          bookingPackage: "General Reservations",
          bookingDate: formData.bookingDate,
          bookingSlotStartTime: formData.bookingSlotStartTime,
          bookingSlotEndTime: formData.bookingSlotStartTime,
          bookingTotalGuest: formData.bookingTotalGuest,
          bookingNotes: formData.bookingNotes,
          outletCode: OutletCode,
          bookingGrouplePackageCategory: "À la Carte",
          reservationDate: new Date().toISOString().split("T")[0],
        },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setFormData({
          bookerFirstName: "",
          bookerLastName: "",
          bookerEmail: "",
          bookerPhone: "",
          bookingDate: "",
          bookingSlotStartTime: "",
          bookingSlotEndTime: "",
          bookingTotalGuest: "",
          bookingNotes: "",
          bookerInternationalCode: "+971",
          reservationDate: new Date().toISOString().split("T")[0],
          outletCode: OutletCode,
          bookingGrouplePackageCategory: "A la carte",
          bookingPackageCategory: "À la Carte",
          bookingPackage: "General Reservations",
        });
        handleClose();
        fetchBookings();
        fetchBookingsCount();
      } else {
        alert(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const disablePastDates = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  return (
    <Transition.Root show={open3} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
                  <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-white z-10 sticky top-0 px-4 py-4 sm:px-6 border-b">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="details">
                            Add Reservation
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md text-black"
                              onClick={handleClose}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between pt-6">
                        <div className="bookingDetails">
                          <h2>Booking Details</h2>

                          <div className="insideDetails">
                            <h3>Booking Date</h3>

                            <div className="mt-1 inner-input w-full">
                              <DatePicker
                                placeholder="Select a date"
                                oneTap
                                format="dd-MMM-yyyy"
                                size="lg"
                                value={new Date(formData.bookingDate)}
                                onChange={handleDateChange}
                                renderExtraFooter={() => null}
                                cleanable={false}
                                shouldDisableDate={disablePastDates}
                                className="custom-date-range-picker2 z-50 text-black"
                              />
                              {errors.bookingDate && (
                                <p className="mt-2 text-sm text-red-600">
                                  {errors.bookingDate}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Time Slot</h3>

                            <div className="mt-1 inner-input ">
                              <div className="sort-wrapper-main-two">
                                <div className="custom-dropdown-two">
                                  <div
                                    className="block w-full rounded-lg border-0 bg-gray-100 py-2.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1  px-3 focus:bg-white sm:text-sm sm:leading-6"
                                    onClick={toggleIndividualTimeSlotOpen}
                                  >
                                    {selectedIndividualTimeSlotTwo ? (
                                      <div className="Time-placeHolder flex justify-between">
                                        <div>
                                          <div className="Time-Value2">
                                            {
                                              individualTimeSlotOptionsTwo.find(
                                                (option) =>
                                                  option.value ===
                                                  selectedIndividualTimeSlotTwo
                                              )?.label
                                            }
                                          </div>
                                        </div>
                                        <div>
                                          <ChevronDownIcon
                                            className={`chevron-icon ${
                                              isIndividualTimeSlotOpenTwo
                                                ? "up"
                                                : "down"
                                            }`}
                                            style={{
                                              transform:
                                                isIndividualTimeSlotOpenTwo
                                                  ? "rotate(180deg)"
                                                  : "none",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="Time-placeHolder flex justify-between">
                                        <div>Select a time slot</div>
                                        <div>
                                          <ChevronDownIcon
                                            className={`chevron-icon ${
                                              isIndividualTimeSlotOpenTwo
                                                ? "up"
                                                : "down"
                                            }`}
                                            style={{
                                              transform:
                                                isIndividualTimeSlotOpenTwo
                                                  ? "rotate(180deg)"
                                                  : "none",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  {isIndividualTimeSlotOpenTwo && (
                                    <div className="timeSlotDropdownIndividualParent">
                                      <div className="timeSlotDropdownIndividualContainer">
                                        {individualTimeSlotOptionsTwo.map(
                                          (option) => (
                                            <div
                                              key={option.value}
                                              style={{
                                                borderRadius: "0.25rem",
                                                backgroundColor:
                                                  selectedIndividualTimeSlotTwo ===
                                                  option.value
                                                    ? "#4B68FE"
                                                    : "",
                                                color:
                                                  selectedIndividualTimeSlotTwo ===
                                                  option.value
                                                    ? "white"
                                                    : "",
                                              }}
                                              className={`timeSlotDropdownIndividual flex justify-between hover:bg-customLilac hover:text-groupleBlue ${
                                                selectedIndividualTimeSlotTwo ===
                                                option.value
                                                  ? "selected"
                                                  : ""
                                              }`}
                                              onClick={() =>
                                                handleSelectedIndividualTimeSlot(
                                                  option.value
                                                )
                                              }
                                            >
                                              {option.label}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {errors.bookingSlotStartTime && (
                                <p className="mt-2 text-sm text-red-600">
                                  {errors.bookingSlotStartTime}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Group Size</h3>
                            <div className="mt-1 inner-input2">
                              <input
                                type="number"
                                name="bookingTotalGuest"
                                id="bookingTotalGuest"
                                value={formData.bookingTotalGuest}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    bookingTotalGuest: e.target.value,
                                  });
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    bookingTotalGuest: "",
                                  }));
                                }}
                                className="date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10"
                              />
                              {errors.bookingTotalGuest && (
                                <p className="mt-2 text-sm text-red-600">
                                  {errors.bookingTotalGuest}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="bookingDetails">
                          <h2>Clients Details</h2>
                          <div className="insideDetails">
                            <h3>First name</h3>

                            <div className="mt-1 inner-input2">
                              <input
                                type="text"
                                name="bookerFirstName"
                                value={formData.bookerFirstName}
                                onChange={handleInputChange}
                                id="bookerFirstName"
                                className="date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 cursor-pointer focus:bg-gray-100 sm:text-sm sm:leading-6"
                              />
                              {errors.bookerFirstName && (
                                <p className="mt-2 text-sm text-red-600">
                                  {errors.bookerFirstName}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Last name</h3>

                            <div className="mt-1 inner-input2">
                              <input
                                type="text"
                                name="bookerLastName"
                                id="bookerLastName"
                                value={formData.bookerLastName}
                                onChange={handleInputChange}
                                className="date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10"
                              />
                              {errors.bookerLastName && (
                                <p className="mt-2 text-sm text-red-600">
                                  {errors.bookerLastName}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Email</h3>

                            <div className="mt-1 inner-input2">
                              <input
                                type="email"
                                name="bookerEmail"
                                value={formData.bookerEmail}
                                onChange={handleInputChange}
                                id="bookerEmail"
                                className="date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10"
                              />
                              {errors.bookerEmail && (
                                <p className="mt-2 text-sm text-red-600">
                                  {errors.bookerEmail}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Phone Numbers</h3>

                            <div className="mt-1 inner-input2">
                              <PhoneInput
                                country={"ae"}
                                value={formData.bookerPhone}
                                onChange={(value, country) => {
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    bookerPhone: value,
                                    bookerInternationalCode: country.dialCode,
                                  }));
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    bookerPhone: "",
                                  }));
                                }}
                                className="date-input block w-full rounded-lg border-0 bg-gray-100 py-1.5 text-black shadow-sm ring-1 ring-inset ring-white/10"
                              />
                              {errors.bookerPhone && (
                                <p className="mt-2 text-sm text-red-600">
                                  {errors.bookerPhone}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Reservation Notes</h3>

                            <div className="mt-1 inner-input2">
                              <textarea
                                name="bookingNotes"
                                id="bookingNotes"
                                value={formData.bookingNotes}
                                onChange={handleInputChange}
                                className="date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10"
                              ></textarea>
                              {errors.bookingNotes && (
                                <p className="mt-2 text-sm text-red-600">
                                  {errors.bookingNotes}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="bookingDetails">
                          <h2>Package Details</h2>
                          <div className="insideDetails">
                            <h3>Category</h3>

                            <div className="mt-1 inner-input2 block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              A la carte
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Package</h3>

                            <div className="mt-1 inner-input2 block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              Regular
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Package Name</h3>

                            <div className="mt-1 inner-input2 block w-full rounded-lg border-0 bg-white-100  py-2.5 text-black  px-3 sm:text-sm sm:leading-6">
                              Individual package
                            </div>
                          </div>
                        </div>

                        <div className="bookingDetails">
                          <h2>Price Details</h2>

                          <div className="insideDetails">
                            <h3>Actual Per Person</h3>

                            <div className="mt-1 inner-input2">
                              <input
                                type="number"
                                // name="bookerEmail"
                                // value={formData.bookerEmail}
                                // onChange={handleInputChange}
                                // id="bookerEmail"
                                className="date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10"
                              />
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Discounted Per Person</h3>

                            <div className="mt-1 inner-input2">
                              <input
                                type="number"
                                // name="bookerEmail"
                                // value={formData.bookerEmail}
                                // onChange={handleInputChange}
                                // id="bookerEmail"
                                className="date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10"
                              />
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Total Discount</h3>

                            <div className="mt-1 inner-input2">
                              <input
                                type="number"
                                // name="bookerEmail"
                                // value={formData.bookerEmail}
                                // onChange={handleInputChange}
                                // id="bookerEmail"
                                className="date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10"
                              />
                            </div>
                          </div>

                          <div className="insideDetails">
                            <h3>Total</h3>

                            <div className="mt-1 inner-input2">
                              <input
                                type="number"
                                // name="bookerEmail"
                                // value={formData.bookerEmail}
                                // onChange={handleInputChange}
                                // id="bookerEmail"
                                className="date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bookingDetails">
                        <h2>Complimentary Notes</h2>
                        <div
                          className="insideDetails"
                          style={{ width: "100%" }}
                        >
                          <div className="mt-1" style={{ width: "100%" }}>
                            <textarea
                              name="complimentaryNotes"
                              id="complimentaryNotes"
                              className="date-input block w-full rounded-lg border-0 bg-gray-100 py-2.5 px-3 text-black shadow-sm ring-1 ring-inset ring-white/10"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="flex flex-shrink-0 justify-center px-4 py-4">
                        <button
                          type="button"
                          onClick={handleAddReservation}
                          className="w-full inline-flex justify-center rounded-md bg-groupleBlue px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                        >
                          Save Reservation
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
