import React, { useEffect, useState, useRef } from "react";
import ApexCharts from "apexcharts";
import axios from "axios";
import { handleApiError } from "./apiErrorHandling";
import { useEstateCode } from "./EstateCodeContext";
import trafficEmpty from "../Images/anlytics empty.png"


const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};


const TrafficChart = () => {
  const [chartData, setChartData] = useState([]);
  const { estateCode } = useEstateCode();
  const chartRef = useRef(null);

  const fetchData = async () => {
    try {
      const url = getApiUrl(`/api/v2/merchant/bookings/viewDashboardPackage/${estateCode}`);
    const response = await axios.get(url, {
      withCredentials: true,
    });

      switch (response.status) {
        case 200:
          setChartData(response.data.data);
          break;
        case 204:
          console.warn("No content available");
          break;
        default:
          console.warn(`Unexpected status code: ${response.status}`);
          break;
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [estateCode]);

  useEffect(() => {
    if (chartData.length > 0) {
      const chartOptions = {
        series: chartData.map((data) => data.distinct_count),
        labels: chartData.map((data) => data.bookingPackage),
        colors: [
          "#b27f7d",
          "#8b9ad0",
          "#d1a86b",
          "#9e9f9f",
          "#8fc1e4",
          "#46597b",
        ],
        chart: {
          height: 320,
          width: "100%",
          type: "donut",
        },
        stroke: {
          colors: ["transparent"],
          lineCap: "",
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontFamily: "Inter, sans-serif",
                  offsetY: 20,
                },
                total: {
                  showAlways: true,
                  show: true,
                  label: "Total Packages Sold",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "0.7rem",
                  formatter: function (w) {
                    const sum = w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                    return sum;
                  },
                },
                value: {
                  show: true,
                  fontFamily: "Inter, sans-serif",
                  offsetY: -20,
                  formatter: function (value) {
                    return value;
                  },
                },
              },
              size: "83%",
            },
          },
        },
        grid: {
          padding: {
            top: -2,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "bottom",
          fontFamily: "Inter, sans-serif",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value;
            },
          },
        },
        xaxis: {
          labels: {
            formatter: function (value) {
              return value;
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
      };

      if (!chartRef.current) {
        chartRef.current = new ApexCharts(
          document.getElementById("donut-chart"),
          {
            ...chartOptions,
            chart: {
              height: 320,
              width: "100%",
              type: "donut",
            },
          }
        );
        chartRef.current.render();
      } else {
        chartRef.current.updateOptions(chartOptions);
      }
    }
  }, [chartData]);

  return (
    <div className="max-w-sm w-full h-fit bg-white rounded-lg shadow dark:bg-white-800  md:p-1">
      <div className="flex flex-col justify-between py-3">
        <div className="font-bold text-xl pl-4 ">Package analysis</div>
        <div className="pl-4 text-groupleBlue font-medium">This month</div>
      </div>
      <div className="py-3" id="donut-chart">
        {chartData.length === 0 && (
          <div className="flex justify-center items-center">
            <img src={trafficEmpty} alt="No traffic data available" style={{height:"15rem"}} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TrafficChart;

