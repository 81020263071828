// EstateCodeContext.js
import React, { createContext, useContext, useState } from "react";

const EstateCodeContext = createContext();

export const EstateCodeProvider = ({ children }) => {
  const storedEstateCode = localStorage.getItem("estateCode");
  const [estateCode, setEstateCode] = useState(storedEstateCode || "GES406");

  const updateEstateCode = (newEstateCode) => {
    setEstateCode(newEstateCode);
    localStorage.setItem("estateCode", newEstateCode);
  };

  return (
    <EstateCodeContext.Provider value={{ estateCode, updateEstateCode }}>
      {children}
    </EstateCodeContext.Provider>
  );
};

export const useEstateCode = () => useContext(EstateCodeContext);
