import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { handleApiError } from "./apiErrorHandling";
import { useEstateCode } from "./EstateCodeContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

export default function Navbar() {
  const { estateCode, updateEstateCode } = useEstateCode();
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [userNavigation, setUserNavigation] = useState([]);

  const fetchUserData = async () => {
    try {
      const userDataUrl = getApiUrl(`/api/v1/merchant/usr/profile/viewUserProfile`);
      const userDataResponse = await axios.get(userDataUrl, { withCredentials: true });
      if (userDataResponse.status === 200) {
        setUserData(userDataResponse.data.data);
      }
    } catch (error) {
      handleApiError(error);
    }
  };



  const fetchUserEstates = async (estateCode) => {
    try {
      const estatesUrl = getApiUrl(`/api/v2/outlet/getUserEstate?estateCode=${estateCode}`);
      const estatesResponse = await axios.get(estatesUrl, { withCredentials: true });
      if (estatesResponse.status === 200) {
        const estates = estatesResponse.data.data;
        const updatedUserNavigation = estates.map((estate) => ({
          name: estate.estate_name,
          description: estate.address,
          estatecode: estate.estatecode,
        }));
        setUserNavigation(updatedUserNavigation);
  
        if (!estateCode && updatedUserNavigation.length > 0) {
          const initialEstateCode = localStorage.getItem('estateCode') || updatedUserNavigation[0].estatecode;
          updateEstateCode(initialEstateCode);
          localStorage.setItem('estateCode', initialEstateCode);
        }
      }
    } catch (error) {
      handleApiError(error);
    }
  };  

  useEffect(()=>{
  fetchUserEstates(estateCode);
  }, [])
  

  useEffect(() => {
    if (Object.keys(userData).length > 0 && userNavigation.length > 0) {
      if (!estateCode) {
        const selectedEstate = userNavigation[0];
        updateEstateCode(selectedEstate.estatecode);
        localStorage.setItem('estateCode', selectedEstate.estatecode);
      }
    }
  }, [userNavigation]);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchUserData();
      await fetchUserEstates();
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleItemClick = (estatecode) => {
    updateEstateCode(estatecode); 
    localStorage.setItem('estateCode', estatecode);
    setSelectedItem(userNavigation.find((item) => item.estatecode === estatecode));
  };

  useEffect(() => {
    window.addEventListener("accountSwitch", handleAccountSwitch);

    return () => {
      window.removeEventListener("accountSwitch", handleAccountSwitch);
    };
  }, []);

  const handleAccountSwitch = async () => {
    setUserData({});
    setUserNavigation([]);
    setSelectedItem(null);
    await fetchUserData();
    await fetchUserEstates();
  
    if (userNavigation.length > 0) {
      const selectedEstate = userNavigation[0];
      updateEstateCode(selectedEstate.estatecode);
      localStorage.setItem('estateCode', selectedEstate.estatecode);
    }
  }


  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="lg:w-full">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            {/* {/ Separator /} */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form
                className="relative flex flex-1"
                action="#"
                method="GET"
              ></form>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/* {/ Profile dropdown /} */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-900">
                      <span className="font-medium leading-none text-white">
                        <span className="text-xl text-white">
                          {estateCode && userNavigation.some(item => item.estatecode === estateCode) ?
                            userNavigation.find(item => item.estatecode === estateCode).name.charAt(0)
                            : ""}
                        </span>
                      </span>
                    </span>
                    <span className="hidden lg:flex lg:items-center">
                      {estateCode && userNavigation.some(item => item.estatecode === estateCode) &&
                        <span
                          className="ml-2 text-base font-semibold leading-6 text-gray-900"
                          aria-hidden="true"
                        >
                          {userNavigation.find(item => item.estatecode === estateCode).name}
                        </span>
                      }
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >

                    <Menu.Items className="absolute right-0 z-10 mt-4 flex flex-col origin-top-right p-2 bg-white rounded-lg border border-violet-200 justify-start items-start gap-2">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => {
                            const isActive = estateCode === item.estatecode;
                            return (
                              <Link
                                className={classNames(
                                  "px-3 py-2 text-md leading-6 w-[285px] items-center flex hover:bg-customLilac hover:text-groupleBlue hover:rounded-md text-black",
                                  isActive ? "bg-gray-50 text-indigo-500" : ""
                                )}
                                onClick={() => {
                                  handleItemClick(item.estatecode);
                                  setSelectedItem(item);
                                }}
                                style={{
                                  paddingLeft: "1rem",
                                  paddingRight: "1rem",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="flex flex-col gap-2">
                                  <div className="font-medium  font-['Inter'] leading-snug">
                                    {item.name}
                                  </div>
                                  <div className="text-xs font-normal font-['Inter'] leading-[14px]">
                                    {item.description}
                                  </div>
                                </div>
                                {isActive && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="#4A6AFE"
                                  >
                                    <path
                                      d="M16.3491 5.66021L7.34912 14.6602C7.27073 14.7389 7.17759 14.8013 7.07503 14.8439C6.97247 14.8865 6.86251 14.9084 6.75146 14.9084C6.64041 14.9084 6.53046 14.8865 6.4279 14.8439C6.32534 14.8013 6.23219 14.7389 6.15381 14.6602L2.21631 10.7227C2.13782 10.6442 2.07556 10.5511 2.03309 10.4485C1.99061 10.346 1.96875 10.2361 1.96875 10.1251C1.96875 10.0141 1.99061 9.90415 2.03309 9.80161C2.07556 9.69906 2.13782 9.60589 2.21631 9.5274C2.29479 9.44892 2.38797 9.38666 2.49051 9.34418C2.59306 9.30171 2.70297 9.27984 2.81396 9.27984C2.92496 9.27984 3.03487 9.30171 3.13741 9.34418C3.23996 9.38666 3.33313 9.44892 3.41162 9.5274L6.75217 12.8679L15.1552 4.46631C15.3137 4.3078 15.5287 4.21875 15.7529 4.21875C15.977 4.21875 16.192 4.3078 16.3505 4.46631C16.509 4.62482 16.5981 4.8398 16.5981 5.06396C16.5981 5.28813 16.509 5.50311 16.3505 5.66162L16.3491 5.66021Z"
                                      fill="#4A6AFE"
                                    />
                                  </svg>
                                )}
                              </Link>
                            );
                          }}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
