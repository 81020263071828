import React, { useEffect, useState } from "react";
import "../Styles/Members.css";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import SlideOverTwo from "./SlideOverTwo";
import axios from "axios";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

const Members = () => {
  const [isSlideOverTwoOpen, setIsSlideOverTwoOpen] = useState(false);
  const [isSlideOverInviteOpen, setIsSlideOverInviteOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [employeeCode, setEmployeeCode] = useState("");
  const [open3, setOpen3] = useState(false);
  const handleInviteClick = () => {
    setIsSlideOverInviteOpen(!isSlideOverInviteOpen);
  };

  const fetchMembers = async () => {
    try {
      const url = getApiUrl(`/api/v2/merchant/roles/viewMembers`);
      const response = await axios.get(url, {
        withCredentials: true,
      });
      if (response.status === 200) {
        setMembers(response.data.data);
      } else {
        throw new Error("Failed to fetch members");
      }
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  const fetchSelectedMemberDetails = async (empCode) => {
    try {
      const apiUrl = getApiUrl(
        `/api/v2/merchant/roles/viewMemberDetail/${empCode}`
      );
      const response = await axios.get(apiUrl, {
        withCredentials: true,
      });
      setEmployeeCode(empCode);
      setSelectedMember(response.data.data);
      setIsSlideOverTwoOpen(true);
    } catch (error) {
      console.error("Error fetching member details:", error);
    }
  };

  const [selectedMemberCode, setSelectedMemberCode] = useState(null);
  const removeMember = async (empCode) => {
    try {
      const url = getApiUrl(`/api/v2/merchant/roles/removeMember/${empCode}`);
      const response = await axios.get(url, {
        withCredentials: true,
      });
      setIsSlideOverTwoOpen(false);
      if (response.status === 200) {
        setMembers((prevMembers) =>
          prevMembers.filter((member) => member.merchant_user_code !== empCode)
        );
      } else {
        throw new Error("Failed to remove member");
      }
    } catch (error) {
      console.error("Error removing member:", error);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const [isOwnerStatus, setIsOwnerStatus] = useState({});
  const fetchUserRoleID = async () => {
    try {
      const url = getApiUrl(`/api/v1/merchant/usr/profile/viewUserProfile`);
      const response = await axios.get(url, { withCredentials: true });
      const userDataFromApi = response.data.data;
      setIsOwnerStatus(userDataFromApi);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserRoleID();
  }, []);

  return (
    <>
      <div className="membersParent">
        <div className="membersChild">
          <h2>Manage Members</h2>
          {isOwnerStatus.roleID === 1 && (
            <button onClick={handleInviteClick}>Invite your team</button>
          )}
        </div>
        <h3>Manage all members in your workspace.</h3>

        {members
          .filter((member) => member.merchant_user_record_status !== 0)
          .map((member, index) => (
            <div
              key={index}
              onClick={() =>
                fetchSelectedMemberDetails(member.merchant_user_code)
              }
              className="border-gray-200 bg-white px-4 py-5 sm:px-6 w-full cursor-pointer hover:bg-[#F0F1F3]"
            >
              <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="ml-4 mt-4">
                  <div className="flex items-center">
                    <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-900">
                      <span className="font-medium leading-none text-white">
                        <span className="text-xl text-white">
                          {member.merchant_user_firstName.charAt(0)}
                        </span>
                      </span>
                    </span>
                    <div className="ml-4">
                      <h3 className="text-base font-semibold leading-6 text-black">
                        {member.merchant_user_firstName}{" "}
                        {member.merchant_user_lastName}
                      </h3>
                      <p className="text-sm text-gray-500">
                        {member.merchant_user_email}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ml-4 mt-4 flex flex-shrink-0">
                  {member.merchant_user_is_spoc === 1 && (
                    <button
                      type="button"
                      className="relative inline-flex items-center rounded-md mr-4 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      <span>SPOC</span>
                    </button>
                  )}

                  <button
                    type="button"
                    className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    <span>{member.merchant_user_designation}</span>
                  </button>

                  {isOwnerStatus.roleID === 1 && (
                    <button
                      type="button"
                      className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpen3(true);
                        setSelectedMemberCode(member.merchant_user_code);
                      }}
                    >
                      <span>Remove</span>
                    </button>
                  )}
                   {/* Remove Popup */}
                   <Transition.Root show={open3} as={Fragment}>
                        <Dialog className="relative z-50" onClose={setOpen3}>
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="fixed inset-0 bg-gray-100 bg-opacity-30 transition-opacity" />
                          </Transition.Child>

                          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                              <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                              >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block"></div>
                                  <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                      <ExclamationTriangleIcon
                                        className="h-6 w-6 text-red-600"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                      <Dialog.Title
                                        as="h3"
                                        className="text-base font-semibold leading-6 text-gray-900"
                                      >
                                        Are you sure you want to remove this
                                        account?
                                      </Dialog.Title>
                                      <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                          All access to this account will be
                                          removed
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-3">
                                    <button
                                      type="button"
                                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                      onClick={() => setOpen3(false)}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                      onClick={(e) => {
                                        removeMember(selectedMemberCode);
                                      }}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </Dialog.Panel>
                              </Transition.Child>
                            </div>
                          </div>
                        </Dialog>
                      </Transition.Root>
                </div>
              </div>
            </div>
          ))}

        {/* invited members */}
        {members.some((member) => member.merchant_user_record_status === 0) && (
          <>
            <h3>Invited Members</h3>
            {members
              .filter((member) => member.merchant_user_record_status === 0)
              .map((member, index) => (
                <div
                  key={index}
                  onClick={() =>
                    fetchSelectedMemberDetails(member.merchant_user_code)
                  }
                  className="border-gray-200 bg-white px-4 py-5 sm:px-6 w-full cursor-pointer hover:bg-[#F0F1F3]"
                >
                  <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                    <div className="ml-4 mt-4">
                      <div className="flex items-center">
                        <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-900">
                          <span className="font-medium leading-none text-white">
                            <span className="text-xl text-white">
                              {member.merchant_user_firstName.charAt(0)}
                            </span>
                          </span>
                        </span>
                        <div className="ml-4">
                          <h3 className="text-base font-semibold leading-6 text-black">
                            {member.merchant_user_firstName}{" "}
                            {member.merchant_user_lastName}
                          </h3>
                          <p className="text-sm text-gray-500">
                            {member.merchant_user_email}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="ml-4 mt-4 flex flex-shrink-0">
                      {member.merchant_user_is_spoc === 1 && (
                        <button
                          type="button"
                          className="relative inline-flex items-center rounded-md mr-4 bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          <span>SPOC</span>
                        </button>
                      )}

                      <button
                        type="button"
                        className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        <span>Invited</span>
                      </button>

                      {isOwnerStatus.roleID === 1 && (
                        <button
                          type="button"
                          className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpen3(true);
                            setSelectedMemberCode(member.merchant_user_code);
                          }}
                        >
                          <span>Remove</span>
                        </button>
                      )}

                     
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}

        {/* ////////// */}
        {isOwnerStatus.roleID === 1 && (
          <>
            {isSlideOverTwoOpen && selectedMember && (
              <SlideOverTwo
                member={selectedMember}
                employeeCode={employeeCode}
                onClose={() => {
                  setIsSlideOverTwoOpen(false);
                }}
                fetchMembers={fetchMembers}
              />
            )}
            {isSlideOverInviteOpen && (
              <SlideOverTwo
                isSlideOverInviteOpen={isSlideOverInviteOpen}
                onClose={() => setIsSlideOverInviteOpen(false)}
                fetchMembers={fetchMembers}
              />
            )}
          </>
        )}

        <h4>Note: Only owner can add and edit the members</h4>
      </div>
    </>
  );
};

export default Members;
