import Navbar from "../Components/Navbar";
import React, { useEffect, useState } from "react";
import plans from "../Images/plans.png";
import "../Styles/Settings.css";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CountryFlag from "react-country-flag";
import upload from "../Images/upload.png";
import { useAuth } from "../Components/AuthContext";
import { useNavigate } from "react-router-dom";
import Members from "../Components/Members";

const getApiUrl = (path) => {
  const hostname = window.location.hostname;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    return `https://server.testgrouple.com${path}`;
  }
  if (hostname.includes("grouple.tech")) {
    return `https://production.grouple.tech${path}`;
  }
  throw new Error("Unknown hostname");
};

export default function Example() {
  const [selectedNavItem, setSelectedNavItem] = useState("Account");
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showImageSizeErrorAlert, setShowImageSizeErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [changePassword, setChangePassword] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [secondaryNavigation, setSecondaryNavigation] = useState([
    { name: "Account", current: true },
    { name: "Plan & Billing", current: false },
    { name: "Members", current: false },
  ]);

  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    setChangePassword({
      ...changePassword,
      [name]: value,
    });
  };

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    localStorage.clear();
    navigate("/");
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        setShowImageSizeErrorAlert(true);
        setTimeout(() => {
          setShowImageSizeErrorAlert(false);
        }, 3000);
        return;
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        setSelectedImage(reader.result);

        const base64Image = reader.result.split(",")[1];
        const imageExtension = file.name.split(".").pop().toLowerCase();
        const mimeType = `image/${imageExtension}`;

        try {
          const apiUrl = getApiUrl("/api/v1/merchant/usr/profile/uploadProfileImage");
          const postdata = {
            imageExtension: imageExtension,
            mimeType: mimeType,
          };

          const res = await axios.post(apiUrl, postdata, {
            withCredentials: true,
          });

          if (res.status === 200) {
            const putImageURL = res.data.data.addImageURL_PUT;

            // Convert base64 to Blob
            const byteCharacters = atob(base64Image);
            const byteArrays = [];
            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += 512
            ) {
              const slice = byteCharacters.slice(offset, offset + 512);

              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: mimeType });

            await axios.put(putImageURL, blob, {
              headers: {
                "Content-Type": mimeType,
              },
              withCredentials: true,
            });

            window.location.reload();
          } else {
            console.error("Failed to upload image:", res.data.message);
            setUploadErrorMessage(res.data.message);
          }
        } catch (error) {
          console.error("Error uploading image:", error);
          setUploadErrorMessage("Error uploading image. Please try again.");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleNavItemClick = (itemName) => {
    setSelectedNavItem(itemName);
    const updatedNavigation = secondaryNavigation.map((item) => ({
      ...item,
      current: item.name === itemName,
    }));
    setSecondaryNavigation(updatedNavigation);
  };

  const handleShowPasswordChange = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (password) => {
    if (password.length < 3) {
      return false;
    }

    if (password.length > 30) {
      return false;
    }

    const alphanumericRegex = /[a-zA-Z0-9]/;
    if (!alphanumericRegex.test(password)) {
      return false;
    }

    const allowedSpecialCharsRegex = /[@#!$%^&*()]/;
    for (let char of password) {
      if (
        !alphanumericRegex.test(char) &&
        !allowedSpecialCharsRegex.test(char)
      ) {
        return false;
      }
    }

    return true;
  };

  const handleSubmitChangePassword = async (event) => {
    event.preventDefault();

    if (!validatePassword(changePassword.new_password)) {
      alert(
        "New password must contain at least 3 alphanumeric characters and may include special characters @#!$%^&*()"
      );
      return;
    }

    if (changePassword.new_password !== changePassword.confirm_password) {
      alert("Confirm password does not match the new password");
      return;
    }

    try {
      const apiUrl = getApiUrl("/api/usr/changePassword");

      const response = await axios.post(
        apiUrl,
        {
          oldPassword: changePassword.current_password,
          password1: changePassword.new_password,
          password2: changePassword.confirm_password,
        },
        { withCredentials: true }
      );

      switch (response.status) {
        case 200:
          setChangePassword({
            current_password: "",
            new_password: "",
            confirm_password: "",
          });
          setShowSuccessAlert(true);
          setShowErrorAlert(false);
          setTimeout(() => {
            handleLogout();
            localStorage.clear();
            sessionStorage.clear();
          }, 2000);
          break;

        case 204:
          setShowSuccessAlert(true);
          setShowErrorAlert(false);
          console.warn("Password changed successfully but no content returned");
          break;

        default:
          setShowErrorAlert(true);
          setShowSuccessAlert(false);
          console.error("Failed to change password:", response.data.message);
          break;
      }
    } catch (error) {
      setShowErrorAlert(true);
      setShowSuccessAlert(false);
      console.error("An error occurred:", error);
    }
  };

  //  Fetching User data initially //////////////////////////////////////////////////////
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    internationalCode: "",
    email: "",
    designation: "",
    profileImage: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    firstName: "",
    lastName: "",
  });

  const nameRegex = /^[^\d\s]+$/;

  const validateForm = () => {
    let valid = true;
    const errors = {
      firstName: "",
      lastName: "",
    };

    if (
      userData.firstName.trim() === "" ||
      !nameRegex.test(userData.firstName)
    ) {
      valid = false;
      errors.firstName =
        "Please enter a valid first name (only letters, no spaces or numbers).";
    }
    if (userData.lastName.trim() === "" || !nameRegex.test(userData.lastName)) {
      valid = false;
      errors.lastName =
        "Please enter a valid last name (only letters, no spaces or numbers).";
    }

    setErrorMessage(errors);
    return valid;
  };

  const fetchUserData = async () => {
    try {
      const apiUrl = getApiUrl("/api/v1/merchant/usr/profile/viewUserProfile");

      const response = await axios.get(apiUrl, { withCredentials: true });
      const userDataFromApi = response.data.data;
      setUserData(userDataFromApi);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [show, setShow] = useState(true);

  const handleInputChange = (field, value) => {
    setUserData({
      ...userData,
      [field]: value,
    });

    setErrorMessage((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleSave = async () => {
    if (!validateForm()) {
      console.log("Form validation failed!");
      return;
    }

    try {
      const apiUrl = getApiUrl("/api/usr/editUserProfile");
      const updatedPhoneNumberWithoutCode = userData.phoneNumber.replace(
        userData.internationalCode,
        ""
      );

      var temp = userData.internationalCode;
      if (!temp.startsWith("+")) {
        temp = "+" + temp;
      }
      const response = await axios.post(
        apiUrl,
        {
          firstName: userData.firstName,
          lastName: userData.lastName,
          countryCode: `${temp}`,
          phoneNumber: updatedPhoneNumberWithoutCode.trim(),
          designation: userData.designation,
        },
        { withCredentials: true }
      );
      setShowSuccessNotification(true);
      setTimeout(() => {
        setShowSuccessNotification(false);
      }, 6000);
      console.log(response);
    } catch (error) {
      console.error("Error editing profile:", error);
    }
  };

  return (
    <>
      <div>
        <Navbar />
        <main>
          <header className="border-b border-white/5 bg-lightBlue">
            {/* {/ Secondary navigation /} */}
            <nav className="flex overflow-x-auto py-4">
              <ul className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8">
                {secondaryNavigation.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className={
                        item.current
                          ? "text-groupleBlue underline cursor-pointer"
                          : "cursor-pointer"
                      }
                      onClick={() => handleNavItemClick(item.name)}
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            {showSuccessNotification && (
              <div className="rounded-lg bg-green-50 border-none my-5 p-4 w-128 mx-auto">
                <div className="ml-3">
                  <h3 className="text-lg font-medium text-black">
                    Your account details have been edited successfully.
                  </h3>
                </div>
              </div>
            )}
            {showImageSizeErrorAlert && (
              <div className="rounded-lg bg-red-50 border-none my-5 p-4 w-128 mx-auto">
                <div className="ml-3">
                  <h3 className="text-lg font-medium text-black">
                    Oops! File size exceeds the limit of 5 MB.
                  </h3>
                </div>
              </div>
            )}

            {uploadErrorMessage && (
              <div className="rounded-lg bg-red-50 border-none my-5 p-4 w-128 mx-auto">
                <div className="ml-3">
                  <h3 className="text-lg font-medium text-black">
                    {uploadErrorMessage}
                  </h3>
                </div>
              </div>
            )}
          </header>

          {/* account section  */}
          {selectedNavItem === "Account" && (
            <>
              <div className="divide-y bg-lightBlue pb-14">
                <div className="grid w-128 rounded-lg border-none mx-auto mb-10 bg-white grid-cols-1 gap-x-8 gap-y-10 px-4 py-10 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div>
                    <h2 className="text-xl font-semibold leading-7 text-black">
                      Admin Information
                    </h2>
                    <p className="mt-1 text-md leading-6 text-gray-400">
                      Update your details for a personalised experience. We
                      respect your privacy.
                    </p>
                  </div>

                  {/* information form  */}
                  <form className="md:col-span-2">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                      {/* upload image  */}
                      <div className="col-span-full flex gap-x-6">
                        <div className="col-span-full flex items-center gap-x-8">
                          <img
                            src={userData.profileImage || upload}
                            alt=""
                            className="h-44 w-44 flex-none rounded-lg object-cover"
                          />

                          <div>
                            <label for="dropzone-file">
                              <p className="rounded-md bg-groupleBlue w-32 px-3 py-2 text-sm cursor-pointer font-semibold text-white shadow-sm  border-groupleBlue ">
                                Change avatar
                              </p>
                              <p className="mt-2 text-xs text-black">
                                Upload your image or avatar in JPG or PNG
                                format.
                              </p>
                              <input
                                id="dropzone-file"
                                type="file"
                                class="hidden"
                                onChange={handleImageChange}
                              />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium leading-6 text-black"
                        >
                          First name
                        </label>
                        <div className="mt-1 inner-input">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            value={userData.firstName}
                            onChange={(e) =>
                              handleInputChange("firstName", e.target.value)
                            }
                            className="block w-full rounded-lg border-0 bg-grey py-2.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 focus:ring-groupleBlue px-3 focus:bg-white sm:text-sm sm:leading-6"
                          />
                          {errorMessage.firstName && (
                            <p className="text-red-500 text-sm mt-1">
                              {errorMessage.firstName}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium leading-6 text-black"
                        >
                          Last name
                        </label>
                        <div className="mt-1 inner-input">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            value={userData.lastName}
                            onChange={(e) =>
                              handleInputChange("lastName", e.target.value)
                            }
                            className="block w-full rounded-lg border-0 bg-grey py-2.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 focus:ring-groupleBlue px-3 focus:bg-white sm:text-sm sm:leading-6"
                          />
                          {errorMessage.lastName && (
                            <p className="text-red-500 text-sm mt-1">
                              {errorMessage.lastName}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-black"
                        >
                          Work email address
                        </label>
                        <div className="mt-1 inner-input">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            value={userData.email}
                            readOnly
                            className="block w-full cursor-not-allowed rounded-lg border-0 bg-grey py-2.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 focus:ring-groupleBlue px-3 focus:bg-white sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="number"
                          className="block text-sm font-medium leading-6 text-black"
                        >
                          Phone number
                        </label>
                        <div className="mt-1 inner-input">
                          <PhoneInput
                            country={"ae"}
                            inputStyle={{
                              width: "100%",
                              height: "3rem",
                              borderRadius: "0.5rem",
                              cursor: "not-allowed",
                              border: "1px solid #a6a6a6",
                            }}
                            dropdownStyle={{
                              padding: "1rem",
                              height: "80rem",
                              width: "35rem",

                              borderRadius: "0.5rem",
                            }}
                            name="phoneNumber"
                            value={userData.phoneNumber}
                            className="phoneNumberInput"
                            onChange={(value, country) => {
                              setUserData({
                                ...userData,
                                internationalCode: country
                                  ? `${country.dialCode}`
                                  : +971,
                                phoneNumber: value || "",
                              });
                            }}
                            maxLength={10}
                            buttonComponent={
                              <CountryFlag
                                internationalCode={userData.internationalCode}
                                svg
                              />
                            }
                          />
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="designation"
                          className="block text-sm font-medium leading-6 text-black"
                        >
                          Designation
                        </label>
                        <div className="mt-1 inner-input">
                          <input
                            id="designation"
                            name="designation"
                            type="text"
                            autoComplete="designation"
                            value={userData.designation}
                            onChange={(e) =>
                              handleInputChange("designation", e.target.value)
                            }
                            className="block w-full rounded-lg border-0 bg-grey py-2.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 focus:ring-groupleBlue px-3 focus:bg-white sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-8 flex">
                      <button
                        type="button"
                        onClick={handleSave}
                        className="rounded-md bg-groupleBlue px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-groupleBlue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>

                {/* // alert // */}
                {showSuccessAlert && (
                  <div className="rounded-lg bg-green-50 border-none my-5 p-4 w-128 mx-auto">
                    <div className="ml-3">
                      <h3 className="text-lg font-medium text-black">
                        Your Password has changed successfully.
                      </h3>
                    </div>
                  </div>
                )}

                {showErrorAlert && (
                  <div className="rounded-lg bg-red-50 border-none my-5 p-4 w-128 mx-auto">
                    <div className="ml-3">
                      <h3 className="text-lg font-medium text-black">
                        Oops! Current Password is wrong. Try again !
                      </h3>
                    </div>
                  </div>
                )}
                {/* Change password */}
                <div className="grid w-128 rounded-lg border-none mx-auto bg-white grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-black">
                      Change password
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                      Update your password associated with your account.
                    </p>
                  </div>

                  <form
                    className="md:col-span-2"
                    onSubmit={handleSubmitChangePassword}
                  >
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                      <div className="col-span-full">
                        <label
                          htmlFor="current-password"
                          type={showPassword ? "text" : "password"}
                          className="block text-sm font-medium leading-6 text-black"
                        >
                          Current password
                        </label>
                        <div className="mt-2 inner-input">
                          <input
                            id="current-password"
                            name="current_password"
                            type={showPassword ? "text" : "password"}
                            autoComplete="current-password"
                            className="block w-full rounded-lg border-0  bg-grey py-2.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 focus:ring-groupleBlue px-3 focus:bg-white sm:text-sm sm:leading-6"
                            value={changePassword.current_password}
                            onChange={handleChangePassword}
                          />
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="new-password"
                          className="block text-sm font-medium leading-6 text-black"
                        >
                          New password
                        </label>
                        <div className="mt-2 inner-input">
                          <input
                            id="new-password"
                            name="new_password"
                            type={showPassword ? "text" : "password"}
                            autoComplete="new-password"
                            className="block w-full rounded-lg border-0  bg-grey py-2.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 focus:ring-groupleBlue px-3 focus:bg-white sm:text-sm sm:leading-6"
                            value={changePassword.new_password}
                            onChange={handleChangePassword}
                          />
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="confirm-password"
                          className="block text-sm font-medium leading-6 text-black"
                        >
                          Confirm password
                        </label>
                        <div className="mt-2 inner-input">
                          <input
                            id="confirm-password"
                            name="confirm_password"
                            type={showPassword ? "text" : "password"}
                            s
                            autoComplete="new-password"
                            className="block w-full rounded-lg border-0  bg-grey py-2.5 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-1 focus:ring-groupleBlue px-3 focus:bg-white sm:text-sm sm:leading-6"
                            value={changePassword.confirm_password}
                            onChange={handleChangePassword}
                          />
                        </div>
                      </div>
                    </div>

                    {/* checkbox */}
                    <div className="space-y-5 mt-4 w-4/5">
                      <div className="relative flex items-end justify-end">
                        <div className="flex h-6 items-center">
                          <input
                            id="show-password"
                            name="show_password"
                            type="checkbox"
                            className="h-5 w-5 rounded border-gray-300 text-groupleBlue focus:ring-groupleBlue"
                            onChange={handleShowPasswordChange}
                          />
                        </div>
                        <div className="ml-3 text-md leading-6">
                          <label
                            htmlFor="show-password"
                            className="font-medium text-black"
                          >
                            Show password
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="mt-8 flex">
                      <button
                        type="submit"
                        className="rounded-md bg-groupleBlue px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-groupleBlue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>

                {/* Logout */}
                <div className="grid w-128 mt-12 rounded-lg border-none mx-auto bg-white grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                  <div>
                    <h2 className="text-base font-semibold leading-7 text-black">
                      Log out
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                      Are you sure you want to log out? Logging out will end
                      your current session.
                    </p>
                  </div>

                  <form className="md:col-span-2" onSubmit={handleLogout}>
                    <div className="mt-8 flex">
                      <button
                        type="submit"
                        className="rounded-md bg-groupleBlue px-5 py-2  text-sm font-semibold text-white shadow-sm hover:bg-groupleBlue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        Log out
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}

          {/* billing section  */}
          {selectedNavItem === "Plan & Billing" && (
            <div className="bg-lightBlue" style={{height:"87vh"}}>
              <div className="divide-y bg-white py-5 px-5 w-128 mx-auto rounded-md">
                <img src={plans} alt="" />
              </div>
            </div>
          )}

          {/* Members section */}
          {selectedNavItem === "Members" && (
            <div className="bg-lightBlue pb-8 " style={{minHeight:"87vh"}}>
              <div className="divide-y bg-white py-5 px-5 w-128 mx-auto rounded-md">
                <Members />
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
}
