
import "../../Styles/Settings.css";
import React from "react";
import email from "../../Images/email.png";
import { Link } from "react-router-dom";

export default function Email() {


  return (
    <>
      <nav className="w-full px-10 pt-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="34"
          viewBox="0 0 120 34"
          fill="none"
        >
          <g clip-path="url(#clip0_387_165)">
            <path
              d="M24.8696 8.72081L24.5708 6.83188H20.8219V23.1817H24.8696V14.5562C24.8696 11.5397 26.826 10.4122 28.951 10.4122C30.2762 10.4122 31.0424 10.7784 31.8712 11.4723L33.6975 7.95463C32.8012 7.05836 31.1773 6.39819 29.4859 6.39819C27.7946 6.39819 26.1369 6.69695 24.8744 8.72081H24.8696Z"
              fill="#4B68FE"
            />
            <path
              d="M43.0974 6.50122C37.8884 6.50122 34.6406 10.3465 34.6406 15.0255C34.6406 19.7044 37.8258 23.5498 43.1312 23.5498C48.4365 23.5498 51.6554 19.7334 51.6554 15.0255C51.6554 10.3176 48.3064 6.50122 43.0974 6.50122ZM43.1312 19.8346C40.1773 19.8346 38.6883 17.5119 38.6883 15.0255C38.6883 12.539 40.2159 10.1827 43.1312 10.1827C45.8489 10.1827 47.574 12.568 47.574 15.0255C47.574 17.483 46.0802 19.8346 43.1312 19.8346Z"
              fill="#4B68FE"
            />
            <path
              d="M66.7158 15.1898C66.7158 17.6763 65.0244 19.7676 62.538 19.7676C60.0515 19.7676 58.5915 17.8787 58.5915 15.3874V6.83423H54.5438V15.426C54.5438 20.5001 57.3964 23.4539 61.3092 23.4539C63.5981 23.4539 65.1545 22.6588 66.8459 21.0012L67.1109 23.2226H70.7249V6.83423H66.711V15.1898H66.7158Z"
              fill="#4B68FE"
            />
            <path
              d="M83.9687 6.46851C82.1135 6.46851 79.921 7.26359 78.6633 9.08988L78.3983 6.83473H74.6156V30.0175H78.6633V21.2619C79.8246 23.0545 82.3785 23.5845 84.1036 23.5845C89.3464 23.5845 92.3629 19.8018 92.3629 15.0265C92.3629 10.2512 89.0139 6.46851 83.9735 6.46851H83.9687ZM83.6699 19.6669C80.9185 19.6669 79.0247 17.5756 79.0247 15.0217C79.0247 12.4678 80.7498 10.3765 83.6699 10.3765C86.5901 10.3765 88.3152 12.4678 88.3152 15.0217C88.3152 17.5756 86.4262 19.6669 83.6699 19.6669Z"
              fill="#4B68FE"
            />
            <path
              d="M99.7233 -0.000244141H95.7094V23.1825H99.7233V-0.000244141Z"
              fill="#4B68FE"
            />
            <path
              d="M111.663 6.33252C106.358 6.33252 102.975 9.91281 102.975 14.8905C102.975 20.1333 106.324 23.6124 111.962 23.6124C114.448 23.6124 117.267 22.7498 118.992 20.9573L116.405 18.4034C115.475 19.3671 113.518 19.9309 112.024 19.9309C109.172 19.9309 107.413 18.4708 107.148 16.447H119.918C120.549 9.71524 117.132 6.33252 111.658 6.33252H111.663ZM107.22 13.098C107.818 10.9777 109.61 9.91281 111.798 9.91281C114.121 9.91281 115.778 10.9729 116.043 13.098H107.22Z"
              fill="#4B68FE"
            />
            <path
              d="M17.9402 3.78491H13.8974C13.8974 5.31726 12.6493 6.5653 11.117 6.5653C10.2159 6.20872 9.2425 6.11716 8.28358 6.11716C3.70582 6.11716 -0.00939941 9.30232 -0.00939941 14.4101C-0.00939941 19.518 3.17576 22.6357 8.28358 22.6357C10.3074 22.6357 12.4277 23.662 12.4277 25.9172C12.4277 28.1723 10.6014 29.4348 8.28358 29.4348C5.96578 29.4348 4.00458 28.0422 4.00458 25.9172H-0.00939941C-0.00939941 30.3263 3.53716 33.2127 8.28358 33.2127C13.03 33.2127 16.4754 30.4275 16.4754 25.9172C16.4754 23.8259 15.8104 21.8695 13.0926 20.5106C15.7767 19.2818 16.5428 16.4292 16.5428 14.4101C16.5428 12.6176 16.0802 10.9937 14.8177 9.56735L14.8418 9.49025C16.7067 8.27112 17.9451 6.17499 17.9451 3.78491H17.9402ZM8.28358 18.9542C5.92723 18.9542 4.00458 17.2628 4.00458 14.4101C4.00458 11.5575 5.92723 9.79865 8.28358 9.79865C10.6399 9.79865 12.5289 11.5912 12.5289 14.4101C12.5289 17.2291 10.6062 18.9542 8.28358 18.9542Z"
              fill="#4B68FE"
            />
          </g>
          <defs>
            <clipPath id="clip0_387_165">
              <rect width="120" height="33.2153" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </nav>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8 pt-20">
        <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col justify-center emailbox">
          <img className="emailimg" src={email} alt="" />
          <h2 className="mt-10 mb-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Email on the way !
          </h2>
          <p className="default-pass">
            We sent your password reset instructions. If it doesn’t show up
            soon, check your spam folder.
          </p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-lg">
          <Link to="/">
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-groupleBlue px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              >
                Return to login
              </button>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
